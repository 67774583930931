<template>
  <base-list v-if="!loading" :items="items" />
</template>

<script>
import { inject, onMounted } from '@vue/composition-api';
import { useLinkedObjects } from '@/modules/linked-objects/compositions/linkedObjects';
import { useLinkedObjectListItems } from '@/modules/linked-objects/compositions/linkedObjectListItems';

export default {
  name: 'ObjectCardLinkedObjects',

  setup() {
    const objectId = inject('objectId');

    const { linkedObjectList, load, loading, refetch } = useLinkedObjects(
      objectId
    );

    const { items } = useLinkedObjectListItems(
      linkedObjectList,
      objectId,
      refetch
    );

    onMounted(() => {
      load();
    });

    return {
      items,
      loading
    };
  }
};
</script>

<style></style>
