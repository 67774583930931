import { useLinkedObjectMenu } from './linkedObjectMenu';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { genAddItem } from '@/utils/list-generators';

import DeviceListItem from '@/modules/objects/ui/object-card/linked-objects/DeviceListItem.vue';
import { computed, ref } from '@vue/composition-api';
import { useDeviceStatus } from './deviceStatus';
import { usePopup } from '@/compositions/popup';
import useMedia from '@/compositions/media';
import DatasetListItem from '@/modules/objects/ui/object-card/linked-objects/DatasetListItem';

export function useLinkedObjectListItems(linkedObjects, objectId, refetch) {
  const { getNextRoute } = useHistoryStack();
  const { genMenu } = useLinkedObjectMenu();
  const { computeIcon } = useMedia();

  const popup = usePopup();

  const genItem = item => {
    switch (item.schemaType) {
      case 'device':
        return genDeviceItem(item);
      case 'dataset':
        return genDatasetItem(item);
    }
  };

  const genDatasetItem = item => {
    const { id, name, enabled } = item;
    return {
      type: DatasetListItem,
      props: {
        title: name,
        enabled,
        actions: genMenu(item),
        to: getNextRoute({
          component: componentAliases.dc,
          props: {
            linkedObjectId: id
          }
        })
      }
    };
  };

  const genDeviceItem = device => {
    const {
      name,
      enabled,
      muted,
      deviceBatteryLevel,
      deviceBatteryLow,
      deviceResponseStatus,
      deviceDcPower,
      settingsEmulation,
      schema,
      image
    } = device;
    const {
      batteryIcon,
      batteryIconColor,
      statusIcon,
      statusIconColor,
      showBatteryLevel
    } = useDeviceStatus({
      batteryLow: ref(deviceBatteryLow?.value),
      dcPower: ref(deviceDcPower?.value)
    });
    return {
      type: DeviceListItem,
      props: {
        title: name,
        enabled,
        batteryLevel: deviceBatteryLevel?.value,
        image: computeIcon(schema) || image,
        emulation: settingsEmulation?.value,
        batteryIcon: batteryIcon?.value,
        batteryIconColor: batteryIconColor?.value,
        statusIcon: statusIcon?.value,
        statusIconColor: statusIconColor?.value,
        showBatteryLevel: showBatteryLevel?.value,
        showNoResponseIcon: !deviceResponseStatus?.value,
        actions: genMenu(device),
        muted,
        to: getNextRoute({
          component: componentAliases.dc,
          props: {
            linkedObjectId: device.id
          }
        })
      }
    };
  };

  const handleAddExistingDevice = async () => {
    popup.open({
      component: () =>
        import('@/modules/linked-objects/ui/AddExistingObject.vue'),
      props: {
        objectId
      },
      listeners: {
        submit: refetch
      }
    });
  };
  const handleAddNewDevice = () => {
    popup.open({
      component: () =>
        import('@/modules/linked-objects/ui/LinkedObjectEdit.vue'),
      props: {
        objectId
      },
      listeners: {
        submit: refetch
      }
    });
  };

  const items = computed(() => {
    return [
      ...linkedObjects.value.map(i => genItem(i)),
      genAddItem(
        { title: 'Add existing object' },
        { click: handleAddExistingDevice }
      ),
      genAddItem({ title: 'Add new object' }, { click: handleAddNewDevice })
    ];
  });

  return {
    items
  };
}
