<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item v-bind="$attrs" :to="to" exact @click="$emit('click')">
      <v-list-item-avatar :color="colors['default']">
        <v-icon color="white" v-text="'$dataset'" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
        <v-list-item-subtitle class="d-flex align-center">
          <div class="mr-2" :class="{ 'red--text': !enabled }">
            {{ enabled ? 'Enabled' : 'Disabled' }}
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="actions.length > 0 && hover">
        <dropdown-menu
          item-key-text="name"
          :items="actions"
          offset-y
          @input="$emit('clickAction', $event)"
        />
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import { colors } from '@/compositions/map/utils/data';

export default {
  name: 'DatasetListItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    to: {
      type: [Object, String],
      default: ''
    }
  },
  setup() {
    return {
      colors
    };
  }
};
</script>
