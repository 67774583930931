<template>
  <v-hover v-slot:default="{ hover }">
    <v-list-item v-bind="$attrs" :to="to" exact @click="$emit('click')">
      <v-list-item-avatar>
        <v-img cover :src="image" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
        <v-list-item-subtitle class="d-flex align-center">
          <div class="mr-2" :class="{ 'red--text': !enabled }">
            {{ enabled ? 'Enabled' : 'Disabled' }}
          </div>
          <div class="d-flex mr-2">
            <v-icon small :color="batteryIconColor">{{ batteryIcon }}</v-icon>
            <div v-if="showBatteryLevel" :style="`color: ${batteryIconColor}`">
              {{ +batteryLevel + '%' }}
            </div>
          </div>
          <v-icon v-if="showNoResponseIcon" class="mr-2 red--text" small>
            $status_no_response
          </v-icon>
          <v-icon
            v-if="statusIcon"
            class="mr-2"
            small
            :color="statusIconColor"
            >{{ statusIcon }}</v-icon
          >
          <v-icon v-if="emulation" class="mr-2" small>
            $emulation
          </v-icon>
          <v-icon v-if="muted" class="mr-2" small v-text="'$mute'" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-show="actions.length > 0 && hover">
        <dropdown-menu
          item-key-text="name"
          :items="actions"
          offset-y
          @input="$emit('clickAction', $event)"
        />
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  name: 'DeviceListItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: false
    },
    showBatteryLevel: {
      type: Boolean,
      default: true
    },
    batteryLevel: {
      type: Number,
      default: 100
    },
    batteryIcon: {
      type: String,
      default: ''
    },
    batteryIconColor: {
      type: String,
      default: ''
    },
    statusIcon: {
      type: String,
      default: ''
    },
    statusIconColor: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: require('@/assets/device.png')
    },
    emulation: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    muted: {
      type: Boolean,
      default: false
    },
    showNoResponseIcon: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, String],
      default: ''
    }
  }
};
</script>
